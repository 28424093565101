<template>
  <div class="member-list">
    <div v-for="member in members" :key="member.id" class="member-list__item" :class="{ 'member-list__item--admin': member.is_head }">
      <div class="member-list__logo">
        <img v-if="member.member_avatar" :src="member.member_avatar" class="member-list__avatar" alt="Member photo">
        <img v-else src="@/assets/img/user-empty-photo.svg" class="member-list__avatar" alt="Member photo">
        <img v-if="member.is_head" src="@/assets/img/team/crown-icon.svg" class="member-list__crown" alt="Crown icon">
      </div>
      <div class="member-list__info">
        <div class="member-list__name">{{ member.member_name }}</div>
        <div v-if="controls === 'list'" class="member-list__row">Дата добавления: <b>{{ member.created_at }}</b></div>
        <div v-else-if="controls === 'invites'" class="member-list__row">Дата подачи заявки: <b>{{ member.created_at }}</b></div>
        <div v-else class="member-list__row">Дата отправки приглашения: <b>{{ member.created_at }}</b></div>
        <div v-if="admin && !member.is_head && controls === 'list'" class="member-list__controls">
          <button
            @click="onMemberDelete($event, member.id)"
            type="button"
            class="link link--gray"
          >
            Исключить
          </button>
          <button
            @click="onChangeAdmin($event, member.id)"
            type="button"
            class="link"
          >
            Передать права
          </button>
        </div>
        <div v-else-if="admin && !member.is_head && controls === 'invites'" class="member-list__controls">
          <button
            @click="onMemberDelete($event, member.id)"
            type="button"
            class="link link--gray"
          >
            Отклонить
          </button>
          <button
            @click="onAcceptInvite($event, member.id)"
            type="button"
            class="link link--right"
          >
            Принять
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberList',
  props: ['members', 'admin', 'teamId', 'controls'],
  methods: {
    onMemberDelete(e, memberId) {
      this.$store.dispatch('team/DELETE_MEMBER', { teamId: this.$props.teamId, memberId })
        .then(() => {
          if (this.$props.controls === 'list') {
            this.$notify({
              type: 'success',
              title: 'Внимание!',
              text: 'Участник успешно исключен'
            })
          } else {
            this.$notify({
              type: 'success',
              title: 'Внимание!',
              text: 'Заявка отклонена'
            })
          }
          this.$emit('updateTeam')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка удаления участника'
          })
        })
    },
    onChangeAdmin(e, memberId) {
      this.$store.dispatch('team/POST_CHANGE_ADMIN', { teamId: this.$props.teamId, data: {id: memberId} })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Администратор команды успешно изменен'
          })
          this.$emit('updateTeam')
          this.$emit('changeAdmin')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка изменения администратора'
          })
        })
    },
    onAcceptInvite(e, memberId) {
      this.$store.dispatch('team/POST_ACCEPT_INVITE', { teamId: this.$props.teamId, data: { id: memberId } })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Внимание!',
            text: 'Участник успешно добавлен'
          })
          this.$emit('updateTeam')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка одобрения заявки'
          })
        })
    },
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.member-list
  display: grid
  grid-template: auto / repeat(auto-fit, minmax(390px, 1fr))
  grid-gap: 40px

  +max-w($mobile_md)
    grid-template: auto / 1fr

.member-list__item
  display: flex

  +max-w($mobile_sm)
    flex-direction: column
    align-items: center
    text-align: center

.member-list__item--admin
  order: -1

.member-list__logo
  position: relative
  overflow: hidden
  flex-shrink: 0
  width: 85px
  height: 110px
  margin-right: 20px
  border-radius: 6px

.member-list__avatar
  width: 100%
  height: 100%
  object-fit: cover

.member-list__crown
  position: absolute
  bottom: 1px
  left: 3px

.member-list__name
  margin-bottom: 10px
  color: $color-theme
  font-size: 24px
  font-weight: 600

.member-list__row
  margin-bottom: 10px
  font-size: 16px

  b
    display: block
    font-weight: 600

.member-list__controls
  display: flex
  align-items: center

  +max-w($mobile_sm)
    justify-content: center

  button
    margin: 0 10px
</style>