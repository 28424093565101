<template>
  <div>
    <h2 v-if="invites.length" class="content__title title">Заявки на вступление</h2>
    <MemberList
      v-if="invites.length"
      @updateTeam="fetchMembers"
      :members="invites"
      :admin="team.is_admin"
      :teamId="team.id"
      controls="invites"
    />
    <div v-else class="slug">
      <div class="slug__img">
        <img src="@/assets/img/team/team-slug.svg" alt="Slug">
      </div>
      <p class="slug__text">У Вас нет заявок</p>
    </div>
  </div>
</template>

<script>
import MemberList from '@/components/MemberList'

export default {
  name: 'TeamInvites',
  props: ['team'],
  components: {
    MemberList,
  },
  data() {
    return {
      invites: [],
    }
  },
  mounted() {
    this.fetchMembers()
  },
  methods: {
    fetchMembers() {
      this.$store.dispatch('team/GET_MEMBERS', { id: this.$route.params.id, status: 'application' })
        .then(response => {
          this.invites = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
          this.$router.push({name: 'my-teams'})
        })
    },
  },
}
</script>